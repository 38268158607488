import React from 'react';
import { Grid, Paper } from '@mui/material';
import mapboxgl from 'mapbox-gl';

const Map = () => {
  const mapRef = React.useRef();
  const mapContainerRef = React.useRef();

  React.useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZ29vZG1vbnN0ZXIiLCJhIjoiY202YjdramlmMDVsazJrcTJiYmdrdjJubSJ9.wgDfq68Pkcg7jrBQfqbW8Q';
    
    if (mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current, // Ensure this is a valid DOM element
        style: 'mapbox://styles/mapbox/streets-v11', // Add a style URL
        center: [0, 0], // Initial map center in [lng, lat]
        zoom: 2, // Initial map zoom level
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{  
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          border: 1,
          borderStyle: "dotted",
          borderRadius: "5px",
          marginTop: 1
        }}
      >
        <div id='map-container' ref={mapContainerRef} style={{ width: '100%', height: '500px' }} />
      </Grid>
    </>
  );
};

export default Map;